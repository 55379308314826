<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="header">
            <div>
                <img src="../../assets/images/draw_table.png" alt="">
            </div>
            <div class="middle">
                <p>爱阿啊</p>
                <p>当前等级：普通</p>
                <p>会员积分：0</p>
            </div>
            <div class="rightBtn" @click="goPage('integrate')">
                <button>查看明细</button>
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .container{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #eee;
        .header{
            width: 100%;
            height: 100px;
            background-color: #fff;
            margin: 4px 0;
            display: flex;
            align-items: center;
            justify-content: space-around;
            img{
                width: 40px;
                height: 40px;
            }
            .middle{
                height: 80px;
                font-size: 12px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-around;
                p{
                    margin: 0;
                    padding: 0;
                    &:first-child{
                    font-size: 16px;
                    
                }
                &:nth-of-type(2){
                    color: #3faaaa;
                }
                }
            }
            .rightBtn{
                align-self: flex-end;
                button{
                    border: none;
                    background-color: #eee;
                    font-size: 14px;
                    border-radius: 6px;
                    margin: 0 0 6px 0;
                    &:active{
                        background-color: #ddd;
                    }
                }
            }
        }
    }
</style>